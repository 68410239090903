@use '../src/app/commons/styles/colors.scss';

@font-face {
	font-family:"futura-pt";
	src:url("assets/fonts/FuturaPTBook.otf") format("opentype");
}

@font-face {
	font-family:"futura-pt-demi";
	src:url("assets/fonts/FuturaPTDemi.otf") format("opentype");
}

@font-face {
	font-family:"futura-pt-bold";
	src:url("assets/fonts/FuturaPTBold.otf") format("opentype");
}


html,
body {
	font-family: "futura-pt",sans-serif;
	height: 100%;
	margin: 0;
	box-sizing:border-box;
}


/********************************Scroll*****************************************/
::-webkit-scrollbar-thumb {
	padding: 0px 4px;
	border-radius: 5px;
	background-color: #D4D4D4;
}

::-webkit-scrollbar {
	background-color: transparent;
	height: 4px;
	width: 5px;
}

::-webkit-scrollbar-track{
	background-color: transparent;
}